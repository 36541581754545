import { Container, Stack, Title } from '~/components'
import { Layout } from '~/components/Layout'
import { Exhibitors, Plan } from '~/features/exhibitors'
import { ImpressionStory } from '~/features/impressions'
import { Poll } from '~/features/polls/types'
import { Gallery } from './Gallery'
import * as styles from './Home.css'
import { Intro } from './Intro'
import { Polls } from './Polls'
import { Review } from './Review'
import { Search } from './Search'

type Props = {
  impressions: ImpressionStory[]
  polls: Poll[]
}

export function Home({ impressions, polls }: Props) {
  return (
    <Layout>
      <Stack spacing="xlarge" includeLastSpace>
        <div>
          <Intro />
          <Search />
          {/*<LotteryCTA home />*/}
        </div>

        <Container>
          <Polls polls={polls} />
        </Container>

        <Container>
          <div css={styles.plan}>
            <div css={styles.planIntro}>
              <Title label="Hallenplan">Wer war wo?</Title>
              <a href="/LIAN_Ne24_Webseite_Plan_A3_Web.pdf" target="_blank" css={styles.planPdf}>
                Saalplan, PDF ↗
              </a>
            </div>
            <div css={styles.planMain}>
              <Plan />
            </div>
          </div>
        </Container>

        <div css={styles.exhibitors}>
          <div css={styles.exhibitorsContent}>
            <div css={styles.exhibitorsHeader}>
              <Title label="Aussteller:innen" spacing="xlarge">
                Entdecke deine
                <br /> Zukunft
              </Title>
            </div>
            <Exhibitors />
          </div>
        </div>

        <Container>
          <Review />
        </Container>

        {impressions.map((impression, index) => (
          <Gallery impression={impression} key={impression.id} reverse={index % 2 === 1} />
        ))}
      </Stack>
    </Layout>
  )
}
